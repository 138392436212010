<!--  -->
<template>
  <div class="container">
    <p class="title">功能配置</p>
    <div class="content">
      <p class="content-title">DCR配置</p>
      <div class="open-dcr">
        <span>是否开启DCR功能</span>
        <el-tooltip
          class="item"
          effect="dark"
          content="开启后，对于设置生效的角色，对数据的操作（暂不包含批量导入）均需经过管理员审批后方能生效"
          placement="top"
        >
          <img src="./img/trips.svg" alt="" />
        </el-tooltip>

        <el-switch v-model="isOpenDcr" @change="handleOpenDcr"></el-switch>
      </div>
      <div :class="isOpenDcr ? 'role' : 'role-disabled'">
        <span>DCR生效角色</span>
        <el-tooltip
          class="item"
          effect="dark"
          content="请选择需要DCR生效的用户角色"
          placement="top"
        >
          <img src="./img/trips.svg" alt="" />
        </el-tooltip>
        <div>
          <el-tooltip
            :disabled="isOpenDcr"
            class="item"
            effect="dark"
            content="开启DCR功能后才能操作"
            placement="top"
          >
            <el-radio :disabled="!isOpenDcr" v-model="role" :label="1" @change="handleChangeRole">
              全部角色
            </el-radio>
          </el-tooltip>
          <el-tooltip
            :disabled="isOpenDcr"
            class="item"
            effect="dark"
            content="开启DCR功能后才能操作"
            placement="top"
          >
            <el-radio :disabled="!isOpenDcr" v-model="role" :label="2" @change="handleChangeRole">
              非管理员角色
            </el-radio>
          </el-tooltip>
        </div>
      </div>
      <div :class="isOpenDcr ? 'third-party' : 'third-party-disabled'">
        <span>是否开启三方验证</span>
        <el-tooltip
          class="item"
          effect="dark"
          content="完成系统对接开发后可开启，并提交DCR给指定供应商进行验证清洗"
          placement="top"
        >
          <img src="./img/trips.svg" alt="" />
        </el-tooltip>
        <el-switch v-model="third" @change="handleOpenDcr"></el-switch>
      </div>
    </div>
  </div>
</template>

<script>
import { GetConfig, SetConfig } from '@/api/dcr-config';
export default {
  data() {
    return {
      isOpenDcr: false,
      role: 1,
      third: false,
    };
  },
  created() {
    this.getConfig();
  },
  methods: {
    getConfig() {
      GetConfig({})
        .then(res => {
          console.log(res);
          this.isOpenDcr = res.config.if_open_dcr;
          this.third = res.config.if_optv;
          this.role = res.config.dcr_role;
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    handleOpenDcr(val) {
      console.log(val);
      const req = {
        if_open_dcr: this.isOpenDcr,
        dcr_role: this.role,
        if_optv: this.isOpenDcr ? this.third : false,
      };
      SetConfig({ config: req })
        .then(() => {
          this.$message.success('修改成功');
          this.getConfig();
        })
        .catch(err => {
          this.$message.error(err.message);
          this.getConfig();
        });
    },
    handleChangeRole(val) {
      console.log(val);
      if (this.isOpenDcr) {
        const req = {
          if_open_dcr: this.isOpenDcr,
          dcr_role: this.role,
          if_optv: this.third,
        };
        SetConfig({ config: req })
          .then(() => {
            this.$message.success('修改成功');
          })
          .catch(err => {
            this.$message.error(err.message);
            this.getConfig();
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
